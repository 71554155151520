import React, { useState } from "react";
import authStore from "../../store/auth";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";

const Register = ({ setShow }) => {
  const { register } = authStore;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const { first_name, last_name, bandName, email, password, confirm_password } =
    formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    if (password !== confirm_password) {
      toast.error("Passwords should be same");
      return;
    }

    register({
      email,
      firstName: first_name,
      lastName: last_name,
      status: "active",
      password,
      bandName,
    }).then((res) => {
      res.ok &&
        navigate(
          "/resend-email?token=" +
            res.data.token +
            "&alreadyRegistered=" +
            res.data.alreadyRegistered
        );
    });

    navigate("/verify");
  };

  return (
    <form
      onSubmit={onSubmit}
      className="relative w-full h-full flex gap-3 items-center justify-center flex-col sm:pb-5 pb-10"
    >
      <div className="absolute top-0 left-0">
        <img
          alt="logo"
          className="w-[140px] object-cover"
          src={process.env.PUBLIC_URL + "/assets/icons/logo-xl.png"}
        />
      </div>
      <h1 className="text-4xl font-bold text-white underline sm:mt-14 mt-8">
        Sign up
      </h1>
      <div className="items-center justify-center flex flex-col">
        <div className="flex sm:flex-row flex-col gap-3 w-full items-center sm:w-auto sm:items-start">
          <div className="flex gap-2 flex-col mt-3 text-xl sm:w-[250px] w-[90%]">
            <label className="text-white text-sm ">First Name: </label>
            <input
              name="first_name"
              value={first_name}
              onChange={onChange}
              className="border-[1px] border-zinc-500 bg-[#272727] text-white text-base rounded-md px-4 py-1"
              // className="border-[1px] border-grey bg-transparent rounded-full px-4 py-1"
              required
            />
          </div>

          <div className="flex gap-2 flex-col mt-3 text-xl sm:w-[250px] w-[90%]">
            <label className="text-white text-sm ">Last Name: </label>
            <input
              name="last_name"
              value={last_name}
              onChange={onChange}
              className="border-[1px] border-zinc-500 bg-[#272727] text-white text-base rounded-md px-4 py-1"
              // className="border-[1px] border-grey bg-transparent rounded-full px-4 py-1"
              required
            />
          </div>
        </div>

        <div className="flex gap-2 flex-col mt-3 text-xl sm:w-[510px] w-[90%]">
          <label className="text-white text-sm ">Band Name: </label>
          <input
            name="bandName"
            value={bandName}
            onChange={onChange}
            className="border-[1px] border-zinc-500 bg-[#272727] text-white text-base rounded-md px-4 py-1"
            // className="border-[1px] border-grey bg-transparent rounded-full px-4 py-1"
            required
          />
        </div>
        <div className="flex gap-2 flex-col mt-3 text-xl sm:w-[510px] w-[90%]">
          <label className="text-white text-sm ">Email: </label>
          <input
            name="email"
            value={email}
            onChange={onChange}
            className="border-[1px] border-zinc-500 bg-[#272727] text-white text-base rounded-md px-4 py-1"
            // className="border-[1px] border-grey bg-transparent rounded-full px-4 py-1"
            required
          />
        </div>

        <div className="flex sm:flex-row flex-col gap-3 w-full items-center sm:w-auto sm:items-start">
          <div className="flex gap-2 flex-col mt-3 text-xl sm:w-[250px] w-[90%]">
            <label className="text-white text-sm ">Password: </label>
            <input
              name="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={onChange}
              className="border-[1px] border-zinc-500 bg-[#272727] text-white text-base rounded-md px-4 py-1"
              // className="border-[1px] border-grey bg-transparent rounded-full px-4 py-1"
              required
            />
          </div>

          <div className="flex gap-2 flex-col mt-3 text-xl sm:w-[250px] w-[90%]">
            <label className="text-white text-sm ">Confirm Password: </label>
            <div className="w-full relative">
              <input
                name="confirm_password"
                type={showPassword ? "text" : "password"}
                value={confirm_password}
                onChange={onChange}
                className="w-full border-[1px] border-zinc-500 bg-[#272727] text-white text-base rounded-md px-4 py-1"
                // className="w-full border-[1px] border-grey bg-transparent rounded-full px-4 py-1"
                required
              />
              <button
                className="absolute right-[-30px] top-[10px]"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FiEyeOff /> : <FiEye />}
              </button>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="my-10 mb-20 border-1 border-red text-1xl py-2 w-full bg-red rounded-md text-white"
          // className="border-4 border-red text-2xl py-4 px-8 rounded-full"
        >
          Submit
        </button>
      </div>
      <div className="absolute bottom-0">
        <label className="text-white text-sm ">
          Don't have an account?{" "}
          <button className="text-red font-bold">
            <Link to="/login">Sign Up</Link>
          </button>{" "}
        </label>
      </div>
    </form>
  );
};

export default Register;
