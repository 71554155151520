import React, { useState } from "react";
import FlyerFront from "./FlyerFront";
import FlyerBack from "./FlyerBack";
import ToggleButton from "../ToggleButton";
import ThirdFlyer from "./ThirdFlyer";

const FlayersScreen = ({ theme }) => {
  const [enableState, setEnableState] = useState(false);

  return (
    <section className="  flex flex-col gap-3 items-center">
      <section className=" flex  items-center justify-center gap-4 py-4 mt-3 flex-wrap ">
        <FlyerFront
          enableState={enableState}
          setEnableState={setEnableState}
          theme={theme}
        />
        <FlyerBack
          enableState={enableState}
          setEnableState={setEnableState}
          theme={theme}
        />
        {/* <ThirdFlyer
          enableState={enableState}
          setEnableState={setEnableState}
          theme={theme}
        /> */}
      </section>
    </section>
  );
};

export default FlayersScreen;
