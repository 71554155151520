import React, { useEffect, useState, useRef } from "react";
import authStore from "../../store/auth";
import domtoimage from "dom-to-image";
import { observer } from "mobx-react";
import { toDataURL } from "../../utils/toDataUrl";
import { PLACEHOLDER_IMAGE } from "../../constants";
import html2canvas from "html2canvas";
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
const Poster = ({ theme }) => {
  const [image, setImage] = useState(null);
  const [userImage, setUserImage] = useState("");
  const [qrCode, setQrCode] = useState("");

  const ref = useRef(null);
  const {
    state: { user },
  } = authStore;

  const posterImage = {
    poster: process.env.PUBLIC_URL + "/assets/images/poster.svg",
    poster_white: process.env.PUBLIC_URL + "/assets/images/poster-svg.png",
  };
  const bgImage = process.env.PUBLIC_URL + "/assets/icons/black_template.jpg";

  const images = {
    blackTemplate: process.env.PUBLIC_URL + "/assets/icons/black_template.jpg",
    mail: process.env.PUBLIC_URL + "/assets/icons/mail.png",
    insta: process.env.PUBLIC_URL + "/assets/icons/insta.png",
    venmo: process.env.PUBLIC_URL + "/assets/icons/venmo.png",
    facebook: process.env.PUBLIC_URL + "/assets/icons/facebook.png",
  };

  const fetchUserImage = () => {
    toDataURL(user.image + "?x=1")
      .then((res) => {
        setUserImage(res);
      })
      .catch((error) => {
        console.error("Fetching image failed:", error.message);
      });
  };

  useEffect(() => {
    user && user.image && fetchUserImage();
    user &&
      setQrCode(
        "https://nextquestup.com/api/template/generateQR?url=https://nextquestup.com/audience-requests/" +
          user.userId
      );
  }, [user]);

  function fetchImage() {
    const container = document.getElementById("poster");

    domtoimage
      .toPng(container)
      .then((dataUrl) => {
        setImage(dataUrl);
      })
      .catch((error) => {
        console.error("Error generating image:", error);
      })
      .finally(() => {
        // document.body.removeChild(container3);
      });

    // html2canvas(container).then(function (canvas) {
    //   setImage(canvas);
    // });
  }

  const myFunc = () => {
    if (ref.current) {
      // html2canvas(ref.current).then((canvas) => {
      //   setImage(canvas.toDataURL());
      // });
    }
  };
  useEffect(() => {
    // myFunc();
  }, [userImage, theme]);

  const download = () => {
    const a = document.createElement("a");
    a.href = image;
    a.download = "generated-image.png";
    a.click();
  };

  useEffect(() => {
    fetchImage();
  }, [userImage, theme]);

  let arr = user?.bandName.split(" ");
  let venmoName = user?.venmo.split(" ");
  return (
    <section className="flex justify-center py-5 flex-col items-center gap-5 ">
      <section className="flex flex-col gap-4 items-center justify-center">
        <section
          className={`w-[330px]  relative px-4  h-[500px]  bg-no-repeat flex  flex-col items-center gap-4 xxl:gap-8  text-[12px] bg-contain bg-center  ${
            theme ? "text-white" : "text-black"
          }`}
          ref={ref}
          style={{
            backgroundImage: `url(${
              theme ? posterImage.poster : posterImage?.poster_white
            }) `,
          }}
          id="poster"
        >
          <section className="flex flex-col justify-center items-center   py-2    gap-3 px-4">
            {/* <p
            src={user?.image}
            className=" h-20 rounded w-20 border-[3px] border-black object-contain  "
          ></p> */}

            <img
              className="rounded  w-[90%] h-[150px]  object-contain"
              src={userImage ? userImage : PLACEHOLDER_IMAGE}
              alt="profile"
            />
            <span className="font-semibold text-xl">
              {user?.bandName && (
                <>
                  <span>{0 < arr.length && arr[0]}</span>&nbsp;
                  <span>{1 < arr.length && arr[1]}</span>&nbsp;
                  <span>{2 < arr.length && arr[2]}</span>&nbsp;
                  <span>{3 < arr.length && arr[3]}</span>&nbsp;
                  <span>{4 < arr.length && arr[4]}</span>&nbsp;
                  <span>{5 < arr.length && arr[5]}</span>&nbsp;
                </>
              )}
            </span>
            <div className="head flex justify-center">
              <img
                className="w-16 h-16  object-contain"
                src={qrCode}
                alt="QR"
              />
            </div>
            <h5 className="font-semibold text-[10px]">
              Make&nbsp;A&nbsp;Request
            </h5>
            <p className="text-[10px] text-center ">
              Scan the QR code with your mobile device to view my song list.
            </p>
            <p className="text-[10px] text-center">
              Click on a Song to send your NextQuest
            </p>

            {user.venmo && (
            <span
              class={`flex items-center justify-center  text-center rounded overflow-hidden border-2 border-maroon ${
                theme ? "text-white bg-gray" : "text-black bg-white"
              } mt-1 px-2  p-1 border-red `}
            >
             &nbsp;&nbsp;<span className="font-bold">Venmo </span>&nbsp;
              {user?.venmo && (
                <span className="">
                  &nbsp;<span>{0 < venmoName.length && venmoName[0]}</span>&nbsp;
                  <span>{1 < venmoName.length && venmoName[1]}</span>&nbsp;
                  <span>{2 < venmoName.length && venmoName[2]}</span>&nbsp;
                  <span>{3 < venmoName.length && venmoName[3]}</span>&nbsp;
                  <span>{4 < venmoName.length && venmoName[4]}</span>&nbsp;
                  <span>{5 < venmoName.length && venmoName[5]}</span>
                </span>
              )}&nbsp;&nbsp;
            </span>
          )}
          </section>

          <section className="text-[8px] absolute bottom-[4%]   w-[90%] gap-5 flex  lg:px-3 justify-between  items-center">
            {user.facebook && (
              <span class="flex items-center gap-3 flex-1 ">
                {/* <img src={images.facebook} alt="facebook" class="h-5 w-5" /> */}
                <FaFacebookF size={24} color="" className="bg-[#1877F2] text-white w-5 h-5 p-1 rounded"/>
                <p className="line-clamp-1">{user.facebookHandle}</p>
              </span>
            )}
            {user.instagram && (
              <span class="flex items-center gap-3 flex-1 justify-end">
                {/* <img src={images.insta} alt="instagram" class="h-5 w-5" /> */}
                <FaInstagramSquare size={24} color="" className="logo_color text-backgroundImage w-5 h-5 rounded text-white"/> 
                <p className="line-clamp-1">{user.instaHandle}</p>
              </span>
            )}
          </section>
        </section>
        <button className="bg-red p-2 rounded w-full " onClick={download}>
          DOWNLOAD
        </button>
      </section>
    </section>
  );
};

export default observer(Poster);
