import React, { useRef, useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

import { Pagination, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";

import About_01 from "../component/About/About_01";
import About_02 from "../component/About/About_02";
import About_03 from "../component/About/About_03";
import About_04 from "../component/About/About_04";
import About_05 from "../component/About/About_05";
const About = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNextSlide = (mobile) => {
    setCurrentSlide((prevSlide) =>
      prevSlide + 2 < images.length ? prevSlide + 1 : 0
    );
  };

  const handlePrevSlide = (mobile) => {
    setCurrentSlide((prevSlide) =>
      prevSlide - 1 >= 0 ? prevSlide - 1 : images.length - 1
    );
  };

  const images = [
    process.env.PUBLIC_URL + "/assets/images/landing.png",
    process.env.PUBLIC_URL + "/assets/images/dashboard.png",
    process.env.PUBLIC_URL + "/assets/images/songs.png",
    process.env.PUBLIC_URL + "/assets/images/requests.png",
  ];

  let about_images = [
    process.env.PUBLIC_URL + "/assets/images/About_slider_bg_01.png",
    process.env.PUBLIC_URL + "/assets/images/search-changed-bg.png",
    process.env.PUBLIC_URL + "/assets/images/About_slider_bg_02.png",
    process.env.PUBLIC_URL + "/assets/images/About_slider_bg_03.png",
    process.env.PUBLIC_URL + "/assets/images/About_slider_bg_05.png.jpg",
  ];

  let icons = process.env.PUBLIC_URL + "/assets/icons/arrow.svg";
  const SectionStyle = {
    height: "100vh", // Set height to 100% of viewport height
    width: "75vw",
  };

  const handleSlideChange = () => {
    setCurrentSlide((prevSlide) => prevSlide + 1);
  };

  const handleSlideChangeBack = () => {
    setCurrentSlide((prevSlide) => prevSlide - 1);
  };

  const isFirstSlide = currentSlide === 0;
  const isLastSlide = currentSlide === images.length - 1;

  const [isPagination, setIsPagination] = useState(false);
  return (
    <section>
      <section
        className="relative hidden lg:block"
        style={{ position: "relative" }}
      >
        <Swiper
          direction={"vertical"}
          onSlideChange={handleSlideChange}
          onSlideChangeTransitionEnd={handleSlideChangeBack}
          pagination={{
            clickable: true,
          }}
          navigation={{
            nextEl: ".swiper-button-next", // Define next arrow element
            prevEl: ".swiper-button-prev", // Define previous arrow element
            disabledClass: "swiper-button-disabled", // Disabled class
          }}
          modules={[Pagination, Navigation]}
          className="m-0 h-[100vh] w-[100%] text-black p-0 relative"
        >
          <SwiperSlide className="">
            <About_01 image={about_images[0]} />
          </SwiperSlide>
          <SwiperSlide>
            <About_02 image={about_images[1]} />
          </SwiperSlide>
          <SwiperSlide>
            <About_03 image={about_images[2]} />
          </SwiperSlide>
          <SwiperSlide>
            <About_04 image={about_images[3]} />
          </SwiperSlide>
          <SwiperSlide>
            <About_05 image={about_images[4]} />
          </SwiperSlide>
        </Swiper>

        {/* Arrow elements with icons */}

        <div className="absolute   z-50 sm:right-14  bottom-2   -rotate-90 sm:rotate-0">
          <img
            alt="logo"
            className="w-22 object-cover swiper-button-prev hover:bg-red rotate-180"
            src={icons}
            onClick={(e) => setCurrentSlide((slide) => slide - 1)}
          />
        </div>
        <div className="absolute sm:bottom-2 z-50 sm:right-2  right-2 bottom-2 -rotate-90 sm:rotate-0">
          <img
            alt="logo"
            className="w-22 object-cover swiper-button-next hover:bg-red "
            src={icons}
            onClick={(e) => setCurrentSlide((slide) => slide + 1)}
          />
        </div>
      </section>

      <section
        className="relative lg:hidden block"
        style={{ position: "relative" }}
      >
        <Swiper
          direction={"vertical"}
          onSlideChange={handleSlideChange}
          onSlideChangeTransitionEnd={handleSlideChangeBack}
          pagination={{
            clickable: true,
          }}
          navigation={{
            nextEl: ".swiper-button-next", // Define next arrow element
            prevEl: ".swiper-button-prev", // Define previous arrow element
            disabledClass: "swiper-button-disabled", // Disabled class
          }}
          modules={[Navigation]}
          className="m-0 h-[100vh] w-[100%] text-black p-0 relative"
        >
          <SwiperSlide className="">
            <About_01 image={about_images[0]} />
          </SwiperSlide>
          <SwiperSlide>
            <About_02 image={about_images[1]} />
          </SwiperSlide>
          <SwiperSlide>
            <About_03 image={about_images[2]} />
          </SwiperSlide>
          <SwiperSlide>
            <About_04 image={about_images[3]} />
          </SwiperSlide>
          <SwiperSlide>
            <About_05 image={about_images[4]} />
          </SwiperSlide>
        </Swiper>

        {/* Arrow elements with icons */}

        <div className="absolute   z-50 sm:right-14  bottom-2   -rotate-90 sm:rotate-0">
          <img
            alt="logo"
            className="w-22 object-cover swiper-button-prev hover:bg-red rotate-180"
            src={icons}
            onClick={(e) => setCurrentSlide((slide) => slide - 1)}
          />
        </div>
        <div className="absolute sm:bottom-2 z-50 sm:right-2  right-2 bottom-2 -rotate-90 sm:rotate-0">
          <img
            alt="logo"
            className="w-22 object-cover swiper-button-next hover:bg-red "
            src={icons}
            onClick={(e) => setCurrentSlide((slide) => slide + 1)}
          />
        </div>
      </section>
    </section>
  );
};

export default About;
