import React from "react";
import loadingStore from "../../store/loading";
import { observer } from "mobx-react";
import { BounceLoader } from "react-spinners";

const Loading = () => {
  return (
    <>
      {loadingStore.isLoading && (
        <div className="fixed w-full h-[100vh] top-0 left-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center">
          <BounceLoader color="gray" size={50} speedMultiplier={3} />
        </div>
      )}
    </>
  );
};

export default observer(Loading);
