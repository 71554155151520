import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";

import get_query from "../actions/get_query";
import delete_query from "../actions/delete_query";
import put_query from "../actions/put_query";
import post_query from "../actions/post_query";
import { BASE_URL, SERVER } from "../constants";
import axios from "axios";
import authStore from "./auth";
class SubscriptionStore {
  state = {};

  constructor() {
    makeAutoObservable(this);
  }

  getSubscription = async (data) => {
    const res = await post_query("/subscription/checkout", data);

    if (res.code === 200) {
      return res.data;
    } else {
      toast.error(res.data.message);
      return null;
    }
  };

  addSubscription = async (data) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("x-auth-token")}`,
    };

    await axios
      .post(BASE_URL + "/subscription/success", data, { headers })
      .then((response) => {
        return response.status;
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  cancelSubscription = async (data) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("x-auth-token")}`,
    };

    await axios
      .post(SERVER + "/api/subscription/cancel", { headers })
      .then((response) => {
        toast.success("Subscription Cancel Successfully !");

        authStore.loadUser();
        return response.status;
      })
      .catch((error) => {
        toast.error("Error while Cancel Subscription !");
      });
  };
}

const subscriptionStore = new SubscriptionStore();
export default subscriptionStore;
