import React, { useEffect, useState } from "react";
import { IoMdSearch } from "react-icons/io";
import { PLACEHOLDER_IMAGE } from "../constants";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import userStore from "../store/user";
import { Link } from "react-router-dom";

const Search = () => {
  const { getAllArtists, getTopArtists } = userStore;

  const [artists, setArtists] = useState([]);
  const [topArtists, setTopArtists] = useState([]);
  const [searchText, setSearchText] = useState("");

  const fetchArtists = async () => {
    const data1 = await getAllArtists();
    const data2 = await getTopArtists();
    setArtists(data1);
    setTopArtists(data2?.slice(0, 5));
  };

  useEffect(() => {
    fetchArtists();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleSearch = () => {
  //   const data = topArtists.filter(
  //     (x) =>
  //       x.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
  //       x.lastName.toLowerCase().includes(searchText.toLowerCase())
  //   );
  //   setTopArtists(data);
  // };
  return (
    <div className="m-auto">
      <img
        className="fixed w-screen h-screen top-0 left-0 object-cover"
        src={process.env.PUBLIC_URL + "/assets/images/search-changed-bg.png"}
        alt="search-bg"
      />

      <div
        className="relative bg-[#22252A] md:p-10 xs:p-5 p-2 border-[1px] border-grey rounded-xl md:w-[800px] sm:w-[600px] xs:w-[94%]
      w-[88%] mx-auto  "
      >
        <h1 className="text-center md:text-2xl xs:text-xl  text-sm">
          Find Your NextQuest Artist
        </h1>
        <div className="flex xs:gap-3 gap-1 my-5 md:text-base xs:text-sm text-[8px]">
          <div className="flex-1 flex items-center xs:gap-3 gap-1 bg-[#1B1D1D] p-3 rounded-lg">
            <IoMdSearch />
            <input
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search artist by name or email"
              className="bg-transparent text-white flex-1 outline-none"
            />
          </div>
          <button className=" flex  items-center gap-1 bg-red px-3 rounded-lg w-[80px]">
            <span className="text-[14px]">GO</span>{" "}
            <HiOutlineArrowNarrowRight className="xs:text-[18px] text-[18px]   hover:text-black hover:text-[24px] transition-all duration-500" />
          </button>
        </div>

        <p>{searchText === "" ? "Top Trending Artists" : "Suggestions"}</p>

        <div className=" max-h-[40vh] overflow-auto container-inner">
          {searchText === "" ? (
            <>
              {topArtists.map((x, i) => (
                <Link
                  key={i}
                  to={"/audience-requests/" + x.userId}
                  className="flex items-center gap-5 my-3 bg-[#22252A] p-2 rounded-xl border border-[#485261]
                    hover:bg-[#30343b] duration-150
                    
                    "
                >
                  <img
                    src={x.image ? x.image : PLACEHOLDER_IMAGE}
                    alt="artist"
                    className="w-14 h-14 object-cover rounded-full border-2 border-red"
                  />
                  <div>
                    <h1 className="md:text-lg">
                      {x.firstName} {x.lastName}
                    </h1>
                    <p className="text-[grey] md:text-base text-xs">
                      {x.bio && x.bio?.length > 50
                        ? x.bio?.slice(0, 50) + "..."
                        : x.bio}
                    </p>
                  </div>
                </Link>
              ))}
            </>
          ) : (
            <>
              {artists
                .filter(
                  (x) =>
                    x.firstName
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    x.lastName.toLowerCase().includes(searchText.toLowerCase())
                )
                .map((x, i) => (
                  <Link
                    key={i}
                    to={"/audience-requests/" + x.userId}
                    className="flex items-center gap-5 my-3 bg-[#151515] p-2 rounded-xl"
                  >
                    <img
                      src={x.image ? x.image : PLACEHOLDER_IMAGE}
                      alt="artist"
                      className="w-14 h-14 object-cover rounded-full border-2 border-red"
                    />
                    <div>
                      <h1 className="md:text-lg">
                        {x.firstName} {x.lastName}
                      </h1>
                      <p className="text-[grey] md:text-base text-xs">
                        {x.bio && x.bio?.length > 50
                          ? x.bio?.slice(0, 50) + "..."
                          : x.bio}
                      </p>
                    </div>
                  </Link>
                ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
