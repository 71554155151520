import React, { useEffect, useState, useRef } from "react";
import authStore from "../../store/auth";

import domtoimage from "dom-to-image";
import { observer } from "mobx-react";
import { toDataURL } from "../../utils/toDataUrl";

const ThirdFlyer = ({ enableState, setEnableState }) => {
  const [image, setImage] = useState(null);
  const [userImage, setUserImage] = useState("");
  const [qrCode, setQrCode] = useState("");

  const {
    state: { user },
  } = authStore;

  const images = {
    back: process.env.PUBLIC_URL + "/assets/images/back.png",
    back_white: process.env.PUBLIC_URL + "/assets/images/back_white.png",
  };

  useEffect(() => {
    user &&
      setQrCode(
        "https://nextquestup.com/api/template/generateQR?url=https://nextquestup.com/audience-requests/" +
          user.userId
      );

    {
      enableState && download();
    }
    setEnableState(false);
    // }, [user, enableState]);
  }, [user, enableState]);

  const fetchImage = () => {
    const container = document.getElementById("print");

    console.log(container);
    // container.style.backgroundColor = theme ? "black" : "white";
    // container.style.color = theme ? "white" : "black";
    domtoimage
      .toPng(container)
      .then((dataUrl) => {
        setImage(dataUrl);
      })
      .catch((error) => {
        console.error("Error generating image:", error);
      });
  };

  const download = () => {
    const a = document.createElement("a");
    a.href = image;
    console.log(image);
    a.download = "print-image.png";
    a.click();
  };

  useEffect(() => {
    fetchImage();
    // }, [userImage, theme]);
  }, [qrCode, enableState]);

  return (
    <section
      className={`${"text-black bg-white overflow-hidden border-gray border-2"} max-w-[350px] w-[90%]  mt-4 rounded-lg  bg-center bg-contain px-4 h-[600px]   bg-no-repeat flex  flex-col items-center gap-4  text-[14px] justify-center `}
      // style={{
      id="print"
    >
      <section className="flex flex-col gap-2 w-full">
        <p className="font-semibold text-center xs:text-[34px] text-2xl">
          {user?.firstName}
          &nbsp;
          {user?.lastName}
        </p>
        <p
          className="overflow-hidden p-2 text-justify "
          style={{ textWrap: "pretty", wordBreak: "break-word" }}
        >
          {user?.bio}
        </p>
        {/* <p className="text-justify line-clamp-6 text-[13px] ">
          Once the value of the query parameter is obtained, it can be utilized
          within the React component as needed. This process enables dynamic
          behavior within the component based on the query parameters present in
          the URL. Whether it's customizing the disp
        </p> */}
      </section>
      <h6 className="font-semibold ">Make&nbsp;A&nbsp;Request</h6>
      <div className="head flex justify-center">
        <img className="w-28 h-28 object-contain" src={qrCode} alt="QR" />
      </div>
      <section className="w-[90%] flex flex-col gap-5 ">
        <p className="text-center text-[14px]">
          Scan the QR code with your mobile device to view my song list.
        </p>
        <p className="text-center text-[12px]">
          Click on a Song to send your Nextquest
        </p>
      </section>
    </section>
  );
};

export default observer(ThirdFlyer);
