import React from "react";
import authStore from "../../store/auth";

const Tip = ({ formData, onChange, setSelected , onSubmit:handleSubmit}) => {
  const {
    venmo,
    venmoLink,
    cashAppLink,
    zelleLink,
    zelleName,
    cashAppName,
    
    pdf,
  } = formData;

  const { uploadUserPdf, removeUserPDF } = authStore;

  const handleFileChange = (e) => {
    let data = new FormData();
    data.append("pdf", e.target?.files[0]);

    uploadUserPdf(data);
  };

  const handlePDFDelete = () => {
    removeUserPDF();
  };
  return (
    <div className="py-2 flex justify-center flex-col min-h-[90vh]">
      <section className="flex flex-col gap-3 border-[#303947] flex-1  bg-[#22252A] px-4 py-2">
        <div className="text-[14px] flex flex-col gap-1">
          <label className="font-semibold">Venmo Name: </label>
          <input
            name="venmo"
            value={venmo}
            onChange={onChange}
            maxLength={30}
            placeholder="input venmo name"
            className="border-gray bg-[#323338] rounded p-3 outline-none "
          />
        </div>
        <div className="text-[14px] flex flex-col gap-1">
          <label className="font-semilbold">Venmo Link: </label>
          <input
            name="venmoLink"
            value={venmoLink}
            onChange={onChange}
            
            placeholder="input venmo link"
            className="border-gray bg-[#323338] rounded p-3 outline-none "
          />
        </div>

        <div className="text-[14px] flex flex-col gap-1">
          <label className="font-semilbold">Zelle Name: </label>
          <input
            name="zelleName"
            value={zelleName}
            onChange={onChange}
            placeholder="input zelle name"
            className="border-gray bg-[#323338] rounded p-3 outline-none "
          />
        </div>

        <div className="mt-4 flex items-end gap-5">
          <div className="">
            <label className="block text-sm font-medium text-gray-700">
              {pdf
                ? "You have Already Uploaded a pdf"
                : "Upload PDF File for Zelle Account"}
            </label>
            <div className="mt-1 flex items-center border rounded border-gray px-2 py-2  w-[120px]">
              <label className="relative cursor-pointer  rounded-md font-medium text-red-600 hover:text-red-500 ">
                <span>Choose a file</span>
                <input
                  type="file"
                  className="sr-only bg-gray"
                  accept=".pdf"
                  onChange={handleFileChange}
                />
              </label>
            </div>
          </div>

          {pdf && (
            <button
              className="px-3 py-1 bg-red rounded"
              onClick={handlePDFDelete}
            >
              Remove PDF
            </button>
          )}
        </div>

        <div className="text-[14px] flex flex-col gap-1">
          <label className="font-semilbold">Cash App Name: </label>
          <input
            name="cashAppName"
            value={cashAppName}
            onChange={onChange}
            placeholder="input cash app name"
            className="border-gray bg-[#323338] rounded p-3 outline-none "
          />
        </div>
        <div className="text-[14px] flex flex-col gap-1">
          <label className="font-semilbold">Cash App Link: </label>
          <input
            name="cashAppLink"
            value={cashAppLink}
            onChange={onChange}
            placeholder="input cash app link"
            className="border-gray bg-[#323338] rounded p-3 outline-none "
          />
        </div>
      </section>

      <button
        className="bg-red   rounded p-1 text-[16px]  w-[96%] mx-auto"
        onClick={handleSubmit}
      >
        Save
      </button>
    </div>
  );
};

export default Tip;
