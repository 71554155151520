import { makeAutoObservable } from "mobx";
import get_query from "../actions/get_query";
import post_query from "../actions/post_query";

import { toast } from "react-toastify";
import setAuthToken from "../utils/setAuthToken";
import put_query from "../actions/put_query";
import { BASE_URL } from "../constants";

class AuthStore {
  state = {
    isAuthenticated: false,
    user: null,
    error: null,
  };

  constructor() {
    makeAutoObservable(this);
  }

  setAuthenticated = (value) => {
    this.state.isAuthenticated = value;
  };

  loadUser = async () => {
    setAuthToken(localStorage.getItem("x-auth-token"));
    const res = await get_query("/user/info");
    if (res.code === 200) {
      this.state.isAuthenticated = true;
      const data = await get_query("/user/user/" + res.data.userId);
      this.state.user = data.data;
    } else {
      this.logout();
    }
  };

  login = async (data) => {
    if (!data.email || !data.password) {
      toast.error("Please Provide Credentials !");
      return;
    }
    const res = await post_query("/user/login", data);
    if (res.code === 200) {
      this.state.error = null;
      this.state.isAuthenticated = true;

      localStorage.setItem("x-auth-token", res.data.token);
      localStorage.setItem("x-auth-date", new Date());

      toast.success("Logged in succesfully!");
      this.loadUser();
    } else if (res.code === 400) {
      this.state.user = null;
      this.state.error = res.data;
      this.state.isAuthenticated = false;

      localStorage.removeItem("x-auth-token");

      toast.error("Invalid Credentials ! ");
    } else {
      this.state.user = null;
      this.state.error = res.data;
      this.state.isAuthenticated = false;

      localStorage.removeItem("x-auth-token");

      toast.error(res.data);
    }
  };

  register = async (data) => {
    const res = await post_query("/user/signup", data);

    if (res.code === 200) {
      this.state.error = null;
      // this.state.isAuthenticated = true;

      // window.localStorage.setItem("x-auth-token", res.data.token);

      toast.success("Registration Successful!");
      return { ok: true, data: res.data };
      // this.loadUser();
    } else {
      this.state.user = null;
      this.state.error = res.data;
      this.state.isAuthenticated = false;

      window.localStorage.removeItem("x-auth-token");

      toast.error(res.data);
      return { ok: false, data: {} };
    }
  };

  resendEmail = async (data) => {
    const res = await post_query("/user/resendEmail", data);

    if (res.code === 200) {
      this.state.error = null;

      toast.success("Email send. Please check your inbox.");
      return { ok: true, data: res.data };
      // this.loadUser();
    } else {
      this.state.user = null;
      this.state.error = res.data;
      this.state.isAuthenticated = false;

      window.localStorage.removeItem("x-auth-token");

      toast.error(res.data);
      return { ok: false, data: {} };
    }
  };

  logout = () => {
    localStorage.removeItem("x-auth-token");
    localStorage.removeItem("x-auth-date");
    this.state.isAuthenticated = false;
    this.state.user = null;
    this.state.error = null;

    // this.loadUser();
  };

  updateUser = async (data, id) => {
    const res = await put_query("/user/update/" + id, data);
    if (res.code === 200) {
      this.loadUser();
      return { ok: true };
    } else {
      return { ok: false };
    }
  };

  uploadUserImage = async (data, id) => {
    fetch(BASE_URL + "/user/addImage/" + id, {
      method: "POST",
      body: data,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Image upload failed");
        } else {
          toast.success("Image uploaded successfully");
          this.loadUser();
        }
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
        toast.error("Image is too big");
      });
  };

  uploadUserPdf = async (data) => {
    fetch(BASE_URL + "/user/addPDF", {
      method: "POST",
      body: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("x-auth-token"),
      },
      maxBodyLength: Infinity,
      data: data,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("PDF upload failed");
        } else {
          toast.success("PDF uploaded successfully");
          this.loadUser();
        }
      })
      .catch((error) => {
        console.error("Error uploading PDF:", error);
        toast.error("PDF is too big");
      });
  };
  removeUserPDF = async () => {
    fetch(BASE_URL + "/user/removeZellePDF", {
      method: "POST",

      headers: {
        Authorization: "Bearer " + localStorage.getItem("x-auth-token"),
      },
      maxBodyLength: Infinity,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("PDF Deleted failed");
        } else {
          toast.success("PDF Deleted successfully");
          this.loadUser();
        }
      })
      .catch((error) => {
        console.error("Error uploading PDF:", error);
        toast.error("PDF is too big");
      });
  };
  forgotPassword = async (data) => {
    const res = await post_query("/user/forgetPasswordMail", data);

    if (res.code === 200) {
      toast.success("Reset link has been sent to your email!");
    } else {
      toast.error(res.data);
    }
  };

  resetPassword = async (data) => {
    const res = await put_query("/user/setPasswordFromToken", data);

    if (res.code === 200) {
      toast.success("Your password has been reset!");
      return res.code;
    } else {
      toast.error(res.data);
      return res.code;
    }
  };

  resetAuthenticatedUserPassword = async (data) => {
    const res = await put_query("/user/resetPassword", data);

    if (res.code === 200) {
      toast.success("Your password has been reset!");
    } else {
      toast.error(res.data);
    }
  };

  activateUser = async (data) => {
    const res = await put_query("/user/activate", data);

    if (res.code === 200) {
      toast.success("Your account has been activated!");

      this.state.isAuthenticated = true;
      window.localStorage.setItem("x-auth-token", data.token);
      window.localStorage.setItem("x-auth-date", new Date());
      this.loadUser();

      return res.code;
    } else {
      toast.error(res.data);
      // toast.error("Error while activating your account");
      return res.code;
    }
  };
}

const authStore = new AuthStore();
export default authStore;
