import React, { useEffect, useState, useRef } from "react";

import { observer } from "mobx-react";
import authStore from "../store/auth";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Main from "../component/templates/Main";
import Flayer from "../component/templates/Flayer";

const Template = () => {
  const {
    state: { user },
  } = authStore;

  const navigate = useNavigate();

  const [show, setShow] = useState("template");

  return (
    <section>
      {show === "template" && (
        <Main userId={user?.userId} setShow={setShow} show={show} />
      )}
      {show === "flayer" && <Flayer setShow={setShow} show={show} />}
    </section>
  );
};

export default observer(Template);
