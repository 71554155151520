import React, { useEffect, useState } from "react";
import AddPlaylist from "../component/playlist/AddPlaylist";
import EditPlaylist from "../component/playlist/EditPlaylist";

import playlistStore from "../store/playlist";
import { observer } from "mobx-react";
import authStore from "../store/auth";
import CenterPopup from "../component/layout/CenterPopup";
import songStore from "../store/song";
import SongForm from "../component/song/SongForm";
import LeftSide from "../component/playlist/LeftSide";
import RightSide from "../component/playlist/RightSide";

const Playlist = () => {
  const {
    addPlaylist,
    editPlaylist,
    getPlaylists,
    deletePlaylist,
    editPlaylistStatus,
    setPlaylist,
    state: { playlist, playlists },
  } = playlistStore;

  const [show, setShow] = useState("list");
  const [edit, setEdit] = useState(null);
  const [deletion, setDeletion] = useState(null);

  const {
    state: { songs, song },
    getActiveSongs,
    setSong,
    addSong,
  } = songStore;

  const {
    state: { user },
  } = authStore;

  const [filteredSongs, setFilteredSongs] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchSongText, setSearchSongText] = useState("");

  useEffect(() => {
    user && getActiveSongs(user?.userId);
  }, [user]);

  useEffect(() => {
    setFilteredSongs((filteredSongs) =>
      songs.filter((x) => x?.playlistId === playlist?.playlistId)
    );

    setShow("selected");
  }, [songs, playlist?.playlistId]);

  const [add, setAdd] = useState(false);
  // Songs Related Function
  const handleAddSong = (data) => {
    addSong(data);
  };

  const handleEditPlaylist = () => {
    setEdit(playlist);
  };

  const toggleActive = () => {
    editPlaylistStatus(playlist?.playlistId);
  };

  // PlayList Related Function
  const handleAdd = (data) => {
    addPlaylist(data);

    setShow("selected");
  };

  const handleEdit = (data, id) => {
    editPlaylist(data, id);
  };

  const handleDelete = (id) => {
    deletePlaylist(id);
    setShow("list");
    setEdit(null);
    setDeletion(null);
  };

  useEffect(() => {
    user && getPlaylists(user.userId);
  }, [user]);

  return (
    <div className="mt-12 flex-1 flex  flex-col  lg:w-[94%] w-[92%] px-4 mx-auto pb-10">
      <h2 className="sm:text-5xl text-3xl  font-semibold mb-2">Playlist</h2>

      {/* Coversthe Entire Block  Left + Right */}

      <div className=" rounded  grid grid-cols-12 items-center gap-3 justify-center ">
        {/* Covers the Left Box of the Screen  */}

        <LeftSide
          searchText={searchText}
          setSearchText={setSearchText}
          playlists={playlists}
          setEdit={setEdit}
          setPlaylist={setPlaylist}
          setShow={setShow}
          setDeletion={setDeletion}
          playlist={playlist}
          editPlaylistStatus={editPlaylistStatus}
        />

        <RightSide
          show={show}
          setShow={setShow}
          songs={songs}
          playlist={playlist}
          searchSongText={searchSongText}
          filteredSongs={filteredSongs}
          setSearchSongText={setSearchSongText}
          setAdd={setAdd}
          isSubscribed={user?.subscription_active}
        />

        <div className="pl-10 flex-1">
          {/* If Someone Wants to add a Playlist  */}
          {show === "add" && (
            <AddPlaylist
              handleAdd={handleAdd}
              handleClose={() => setShow("list")}
            />
          )}
        </div>

        {edit && (
          <EditPlaylist
            editPlaylist={edit}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleClose={() => setEdit(null)}
          />
        )}

        {/* Deletion popup Box  */}
        {deletion && (
          <CenterPopup dark={true}>
            <div className="flex flex-col justify-center items-center gap-5">
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/delete-circle-icon.svg`}
                height={36}
                width={36}
                className=" border-[#5E6A7B] cursor-pointer "
              />
              <p>Are you sure you want to delete this Playlist?</p>

              <div className="flex gap-3 items-center">
                <button
                  className="px-6 py-2 rounded bg-[#636363]"
                  onClick={() => setDeletion(null)}
                >
                  Cancel
                </button>
                <button
                  className="px-6 py-2 rounded bg-red"
                  onClick={() => handleDelete(deletion)}
                >
                  Okay
                </button>
              </div>
            </div>
          </CenterPopup>
        )}

        {add && (
          <SongForm
            handleAdd={handleAddSong}
            handleClose={() => setAdd(false)}
            playlistId={playlist?.playlistId}
            status={playlist.status}
          />
        )}
      </div>
    </div>
  );
};

export default observer(Playlist);
