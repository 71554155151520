import React, { useEffect, useState } from "react";
import LiveRequestItem from "./LiveRequestItem";
import { MdOutlineCancel } from "react-icons/md";
import { BsCalendarDate } from "react-icons/bs";
import { IoReloadSharp } from "react-icons/io5";

const LiveRequestList = ({
  data,
  handleAccept,
  handleReject,
  deleteRequests,
  setTotal,
}) => {
  const [date, setDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [showFilter, setShowFilter] = useState(false);
  const [filtered, setFiltered] = useState([]);

  const [removeAll, setRemoveAll] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const filterByDate = () => {
    if (date !== "") {
      let arr = (filteredData) =>
        data.filter((item) => {
          const createdAtDate = new Date(item.createdAt);
          return createdAtDate.toISOString().split("T")[0] === date;
        });
      setFilteredData(
        arr.map((ele) => {
          return { ...ele, status01: false };
        })
      );
      setTotal(arr.length);
    } else {
      let arr = data.map((ele) => {
        return { ...ele, status01: true };
      });
      setFilteredData(arr);
      setTotal(arr.length);
    }
  };

  useEffect(() => {
    filterByDate();
  }, [data]);

  const hanldeRemove = () => {
    const ids = filteredData.filter((data) => data.status01);

    deleteRequests(ids);

    let remainingRequests = filteredData.filter((data) => !data.status01);

    remainingRequests = remainingRequests.map((ele) => {
      return {
        ...ele,
        status01: true,
      };
    });

    setFilteredData(remainingRequests);
    setRemoveAll(false);
  };
  return (
    <div className="mt-10 px-4 my-4 h-[90vh] overflow-y-auto">
      {/* {showFilter ? (
        <div className="flex gap-5 items-center mb-5">
          <input
            className="border-2 border-red rounded px-4 py-2 bg-transparent"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />

          <button
            className="text-red text-xl"
            onClick={() => {
              setDate("");
              setShowFilter(false);
            }}
          >
            <MdOutlineCancel />
          </button>
        </div>
      ) : (
        <button
          className="flex gap-3 text-red items-center underline mb-5"
          onClick={() => setShowFilter(true)}
        >
          <BsCalendarDate /> Date Filter
        </button>
      )} */}

      <section className="mb-3 flex justify-between">
        <p className="flex items-center gap-2 cursor-pointer">
          <input
            type="checkbox"
            style={{
              accentColor: "red",
            }}
            checked={removeAll}
            className="h-6 w-6 p-2  rounded cursor-pointer"
            onChange={() => {
              setRemoveAll((select) => !select);
            }}
          />
          <label htmlFor="check">Ignore Requests</label>{" "}
          {/* <IoReloadSharp
            color="gray"
            className="cursor-pointer"
            onClick={() => {
              // setEmailsDateFilter(null);
              // setSelectAll(false); // Reset selectAll state
            }}
          /> */}
        </p>

        {removeAll && (
          <button
            className="py-2 px-5 bg-red rounded"
            onClick={() => {
              hanldeRemove();
            }}
          >
            Ignore
          </button>
        )}
      </section>

      {filteredData.map((x, i) => (
        <LiveRequestItem
          request={x}
          key={i}
          data={filteredData}
          setData={setFilteredData}
          isVisible={removeAll}
          isSelecetd={selectAll}
          index={i}
          handleAccept={handleAccept}
          handleReject={handleReject}
        />
      ))}
    </div>
  );
};

export default LiveRequestList;
