export function toDataURL(url) {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => {
        if (response.ok) return response.blob();
        throw new Error("Network response was not ok.");
      })
      .then((blob) => {
        var reader = new FileReader();
        reader.onloadend = function () {
          var base64data = reader.result;
          resolve(base64data); // Resolve the Promise with the data URL
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        reject(error); // Reject the Promise if an error occurs
      });
  });
}
