import React, { useEffect, useState } from "react";
import songStore from "../../store/song";

import moment from "moment";
import { isVisible } from "@testing-library/user-event/dist/utils";

const LiveRequestItem = ({
  request: { songId, memo, requestId, createdAt, name, Song, status01 },
  isVisible,
  data,
  setData,
  isSelected,
  index,
  handleAccept,
  handleReject,
}) => {
  // console.log(data);
  // const { getSongById } = songStore;
  // const [song, setSong] = useState({});

  // useEffect(() => {
  //   getSongById(songId).then((res) => setSong(res));
  // }, [songId]);

  let newdate = createdAt.slice(0, 10).split("-");

  let newtime = new Date(createdAt);
  const hours = newtime.getHours();
  const minutes = newtime.getMinutes();

  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  return (
    <div className=" sm:flex-row flex-col flex sm:items-center gap-5 mb-5 p-3 border rounded border-[#303947] bg-[#22252A] drop-shadow-md sm:text-lg xs:text-sm text-[8px]">
      {isVisible && (
        <input
          type="checkbox"
          style={{
            accentColor: "red",
          }}
          checked={status01}
          className="h-6 w-6 p-2  rounded cursor-pointer"
          onChange={() => {
            // setSelectAll((select) => !select);

            setData(
              data.map((ele) => {
                if (requestId === ele.requestId) {
                  return { ...ele, status01: !ele.status01 };
                }
                return ele;
              })
            );
          }}
        />
      )}

      <div className="flex-1 flex gap-5 ">
        <p className="font-bold text-lg self-start">{index + 1}.</p>
        <div className="flex-1">
          <p className=" line-clamp-1">
            {Song ? (
              <>
                <span className="font-bold ">{Song?.title}</span>
                {Song?.artist && <span> by {Song?.artist}</span>}
              </>
            ) : (
              <span className="italic text-gray line-through">
                The Song is Deleted
              </span>
            )}
          </p>
          <p className="text-[#797A7D] line-clamp-1">{name}</p>
          <p className="text-[#797A7D] line-clamp-1">{memo}</p>
        </div>
      </div>
      <div className="flex gap-5 sm:justify-start justify-between items-center">
        <p className="flex items-center gap-5 sm:text-lg xs:text-sm text-[8px]">
          <span>{`${newdate[1]}/${newdate[2]}/${newdate[0]}`}</span>

          <span>{`${formattedHours}:${minutes
            .toString()
            .padStart(2, "0")} ${ampm}`}</span>
        </p>

        <p className="border-[#303947] border bg-gray h-16"></p>
        <div className="flex flex-col  gap-2">
          <button
            onClick={() => handleAccept(requestId)}
            className="border-[1px] bg-[#5BCEA3] text-black rounded sm:px-16 xs:px-6 px-4 py-2"
          >
            Play
          </button>
          <button
            onClick={() => handleReject(requestId)}
            className="py-2 bg-red text-white sm:px-16 xs:px-6 px-4 rounded "
          >
            Ignore
          </button>
        </div>
      </div>
    </div>
  );
};

export default LiveRequestItem;
