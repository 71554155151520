import React, { useEffect, useState, useRef } from "react";
import authStore from "../../store/auth";

import domtoimage from "dom-to-image";
import { observer } from "mobx-react";
import { toDataURL } from "../../utils/toDataUrl";

const FlyerBack = ({ link, theme, enableState, setEnableState }) => {
  const [image, setImage] = useState(null);
  const [userImage, setUserImage] = useState("");
  const [qrCode, setQrCode] = useState("");

  const {
    state: { user },
  } = authStore;

  const images = {
    back: process.env.PUBLIC_URL + "/assets/images/back.png",
    back_white: process.env.PUBLIC_URL + "/assets/images/back_white.png",
  };

  const fetchUserImage = () => {
    toDataURL(user.image + "?x=1")
      .then((res) => {
        setUserImage(res);
      })
      .catch((error) => {
        console.error("Fetching image failed:", error.message);
      });
  };

  useEffect(() => {
    user && user.image && fetchUserImage();
    user &&
      setQrCode(
        "https://nextquestup.com/api/template/generateQR?url=https://nextquestup.com/audience-requests/" +
          user.userId
      );

    {
      enableState && download();
    }
    setEnableState(false);
  }, [user, enableState]);

  const fetchImage = () => {
    const container = document.getElementById("back");

    // container.style.backgroundColor = theme ? "black" : "white";
    // container.style.color = theme ? "white" : "black";
    domtoimage
      .toPng(container)
      .then((dataUrl) => {
        setImage(dataUrl);
      })
      .catch((error) => {
        console.error("Error generating image:", error);
      });
  };

  const download = () => {
    const a = document.createElement("a");
    a.href = image;
    a.download = "generated-image.png";
    a.click();
  };

  useEffect(() => {
    fetchImage();
  }, [userImage, theme]);

  return (
    <section className="flex flex-col gap-3 items-center">
      <section
        className={`${
          theme
            ? "text-white bg-[#000] border-gray border-2"
            : "text-[#000] bg-white border-black border-2"
        } max-w-[350px] w-[90%]     bg-center bg-contain px-4 h-[600px]   bg-no-repeat flex  flex-col items-center gap-4 rounded text-[14px] justify-center `}
        // style={{
        id="back"
      >
        {/* <section className="flex flex-col gap-2">
        <p className="font-semibold text-[14px]">
          {user?.firstName}&nbsp;{user?.lastName}
        </p>
        <p className="text-center line-clamp-2">{user?.bio}</p>
      </section> */}
        <h6 className="font-semibold text-xl">Make&nbsp;A&nbsp;Request</h6>

        <div className="head flex justify-center w-full">
          <img
            className="w-[80%] h-[150px] object-contain"
            src={qrCode}
            alt="QR"
          />
        </div>
        <section className="w-[90%] flex flex-col gap-5 text-center">
          <p className="text-center text-lg">
            Scan the QR code with your mobile device to view my song list.
          </p>
          <p className="text-center text-lg">
            Click on a Song to send your <span className="text-red">N</span>
            ext<span className="text-red">Q</span>uest
          </p>
          {theme && (
            <section
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/icons/logo-xl.png)`,
              }}
              className="w-[120px] h-16  bg-no-repeat bg-contain mx-auto"
            ></section>
          )}
        </section>
        <p>www.nextquestup.com</p>
      </section>
      <button
        className="bg-red p-2 rounded text-[16px] w-[90%]"
        onClick={download}
      >
        DOWNLOAD
      </button>
    </section>
  );
};

export default observer(FlyerBack);
