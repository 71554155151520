
import React, { useState } from 'react';
import AvatarEdit from 'react-avatar-edit';

const AvatarEditorComponent = ({ handleSubmit }) => {
  const [preview, setPreview] = useState(null);

  const handleCrop = (preview) => {
    setPreview(preview);
  };

  const handleClose = () => {
    setPreview(null);
  };

  const onSave = () => {
    if (!preview) return; // Ensure preview is not null

    const [header, data] = preview.split(',');
    const mimeString = header.split(':')[1].split(';')[0];
    const byteString = atob(data);

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const fileName = 'avatar.png'; // You can change this or make it dynamic
    const file = new File([ab], fileName, { type: mimeString });

    handleSubmit(file);
  };

  return (
    <div className="flex flex-col items-center mt-8">
      <h2 className="text-2xl font-semibold mb-4">Edit Your Avatar</h2>
      <AvatarEdit
        width={200}
        height={200}
        onCrop={handleCrop}
        onClose={handleClose}
      />
      {preview && (
        <div className="mt-4">
          <h3 className="text-xl font-medium mb-2">Preview:</h3>
          <img
            src={preview}
            alt="Avatar Preview"
            className="rounded-full w-24 h-24 object-cover"
          />
        </div>
      )}
      <button onClick={onSave} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded">
        Save
      </button>
    </div>
  );
};

export default AvatarEditorComponent;


