import React, { useEffect, useState, useRef } from "react";
import authStore from "../../store/auth";
import { IoMailSharp } from "react-icons/io5";
import domtoimage from "dom-to-image";

import { observer } from "mobx-react";
import { toDataURL } from "../../utils/toDataUrl";
import { PLACEHOLDER_IMAGE } from "../../constants";
// import { ImFacebook2 } from "react-icons/im";
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaGlobeAfrica } from "react-icons/fa";
const FlyerFront = ({ enableState, theme, setEnableState }) => {
  const [image, setImage] = useState(null);
  const [userImage, setUserImage] = useState("");

  const [existingImage, setExistingImage] = useState(null);
  const {
    state: { user },
  } = authStore;

  const images = {
    blackTemplate: process.env.PUBLIC_URL + "/assets/icons/black_template.jpg",
    mail: process.env.PUBLIC_URL + "/assets/icons/mail.png",
    insta: process.env.PUBLIC_URL + "/assets/icons/insta.png",
    facebook: process.env.PUBLIC_URL + "/assets/icons/facebook.png",
    venmo: process.env.PUBLIC_URL + "/assets/icons/venmo.png",
    front: process.env.PUBLIC_URL + "/assets/images/front.png",
    front_white: process.env.PUBLIC_URL + "/assets/images/front_white.png",
  };

  const fetchUserImage = () => {
    toDataURL(user.image + "?x=1")
      .then((res) => {
        setUserImage(res);
      })
      .catch((error) => {
        console.error("Fetching image failed:", error.message);
      });
  };

  useEffect(() => {
    user && user.image && fetchUserImage();
    {
      enableState && download();
    }

    setEnableState(false);
  }, [user, enableState]);

  const fetchImage = () => {
    const container = document.getElementById("front");

    domtoimage
      .toPng(container)
      .then((dataUrl) => {
        setImage(dataUrl);
      })
      .catch((error) => {
        console.error("Error generating image:", error);
      })
      .finally(() => {});
    // });
  };

  const download = () => {
    const a = document.createElement("a");
    a.href = image;
    a.download = "generated.png";
    a.click();
  };

  useEffect(() => {
    fetchImage();
  }, [userImage, theme]);

  let venmoName = user?.venmo.split(" ");
  let name = user?.bandName.split(" ");

  console.log(venmoName, "venmo");

  return (
    <section className="flex flex-col gap-3">
      <section
        className={`w-[320px] rounded-lg  overflow-hidden    bg-contain   bg-center px-4 h-[600px]   bg-no-repeat flex  flex-col items-center justify-center gap-4  text-[12px] ${
          theme
            ? "text-white bg-[#000] border-gray border-2"
            : "text-[#000] bg-white border-black border-2"
        }`}
        // style={{
        //   backgroundImage: `url(${theme ? images.front : images.front_white}) `,
        // }}
        id="front"
      >
        <section className="flex justify-center flex-col gap-2 w-[90%]  items-center text-center">
          {/* <p className="h-[85px] w-20 border text-center"></p> */}
          <img
            className="w-[80%]   object-cover  "
            src={userImage ? userImage : PLACEHOLDER_IMAGE}
            alt="profile"
          />

          {/* <img
          className="h-[200px] w-[200px] rounded-full object-cover border-2 border-grey"
          src={
            selectedImage
              ? URL?.createObjectURL(selectedImage)
              : existingImage
              ? existingImage
              : PLACEHOLDER_IMAGE
          }
          alt="profile"
          style={{ boxShadow: "0px 4px 35px rgba(251, 46, 51, 0.5)" }}
        /> */}
          <span className="font-semibold text-xl">
            {/* {user?.firstName}&nbsp;{user?.lastName} */}
           
           
              {user?.bandName && (
                <span className="">
                  &nbsp;<span>{0 < name.length && name[0]}</span>&nbsp;
                  <span>{1 < name.length && name[1]}</span>&nbsp;
                  <span>{2 < name.length && name[2]}</span>&nbsp;
                  <span>{3 < name.length && name[3]}</span>&nbsp;
                  <span>{4 < name.length && name[4]}</span>&nbsp;
                  <span>{5 < name.length && name[5]}</span>
                </span>
                
              )}
            
          </span>
          <p
            className="text-justify line-clamp-6 "
            style={{ textWrap: "pretty", wordBreak: "break-word" }}
          >
            {user?.bio}
          </p>
        </section>

        <section className="flex flex-col gap-2 text-justify ">
          {user.email && (
            <span class="flex items-center justify-start gap-3 ">
              {/* <img src={images.mail} alt="email" class="h-8 w-8" /> */}
              <div className="w-8 h-8 bg-[#0abde3] flex items-center justify-center rounded-full"><IoMailSharp size={24} color="" className="  w-5 h-5 rounded text-white "/></div>
              <p>{user.email}</p>
            </span>
          )}
          {user.facebookHandle && (
            <span class="flex items-center justify-start gap-4  ">
              {/* <img src={images.facebook} alt="facebook" class="w-8 h-8" /> */}
              <FaFacebookF size={24} color="" className="bg-[#1877F2] pt-[2px] text-white w-7 h-7 rounded"/>
              <p>{user.facebookHandle}</p>
            </span>
          )}
          {user.instaHandle && (
            <span class="flex items-center justify-start gap-4">
              <FaInstagramSquare size={24} color="" className="logo_color text-backgroundImage w-7 h-7 rounded text-white"/>
              {/* <img src={images.insta} alt="instagram" class="w-8 h-8" /> */}
              <p>{user.instaHandle}</p>
            </span>
          )}
           {user.website && (
            <span class="flex items-center justify-start gap-4">
              {/* <img src={images.insta} alt="instagram" class="w-8 h-8" /> */}
              <FaGlobeAfrica class="w-7 h-7"/>
              <p>{user.website}</p>
            </span>
          )}
        </section>
        <section>
          {user.venmo && (
            <span
              class={`flex items-center justify-center  text-center rounded  border-2 border-maroon ${
                theme ? "text-white bg-gray" : "text-black bg-white"
              } mt-1 px-2 p-1 border-red `}
            >
             &nbsp; <span className="font-bold ">Venmo </span>&nbsp;
              {user?.venmo && (
                <span className="">
                  &nbsp;<span>{0 < venmoName.length && venmoName[0]}</span>&nbsp;
                  <span>{1 < venmoName.length && venmoName[1]}</span>&nbsp;
                  <span>{2 < venmoName.length && venmoName[2]}</span>&nbsp;
                  <span>{3 < venmoName.length && venmoName[3]}</span>&nbsp;
                  <span>{4 < venmoName.length && venmoName[4]}</span>&nbsp;
                  <span>{5 < venmoName.length && venmoName[5]}</span>
                </span>
                
              )}&nbsp;&nbsp;
            </span>
          )}
        </section>
      </section>
      <button
        className="bg-red p-2 rounded text-[16px] w-full"
        onClick={download}
      >
        DOWNLOAD
      </button>
    </section>
  );
};

export default observer(FlyerFront);
